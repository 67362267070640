<template>
  <b-modal
    id="select-content-modal"
    ok-disabled
    cancel-disabled
    hide-footer
    :title="$t('tests.choose_file_for_body')"
  >
    <b-button
      class="btn-block mb-1"
      variant="warning"
      @click="selectFile(null, null)"
    >
      {{ $t('general.select') }}
    </b-button>
    <tree-collapse
      class="min-w"
      :all-options="false"
      :folder="GET_FOLDER_LIST"
      :move="true"
      @move-folder="selectFile"
    />
  </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import TreeCollapse from '@/components/TreeCollapse.vue'

export default {
  components: {
    BModal,
    TreeCollapse,
    BButton,
  },
  props: {
    fileId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selected_file: '',
    }
  },
  computed: {
    ...mapGetters('content', ['GET_FOLDER_LIST']),
  },
  methods: {
    ...mapActions('content', [
      'UPDATE_FOLDER',
      'FETCH_FOLDERS_LIST',
    ]),
    onHideFile() {
      this.selected_file = null
    },
    selectFile(e, id) {
      this.UPDATE_FOLDER({ id: this.fileId, parent: id })
        .then(() => {
          this.$_successToast(this.$t('general.edited'))
          this.FETCH_FOLDERS_LIST()
            .then(() => {
              this.$bvModal.hide('select-content-modal')
            })
        })
        .catch(() => {
          this.$_errorToast(this.$t('general.error'))
        })
    },
  },
}
</script>

<style>

</style>
