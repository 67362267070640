<template>
  <b-modal
    id="properties-modal"
    size="md"
    ok-variant="success"
    cancel-variant="danger"
    :ok-title="$t('general.close')"
    :cancel-title="$t('general.cancel')"
    centered
    no-close-on-backdrop
    ok-only
    @show="onShow"
  >
    <!-- @row-clicked="({id}) => $router.push()" -->
    <b-row>
      <b-col
        cols="12"
        class="mt-2"
      >
        <h6>{{ $t('general.title') }}:</h6>
        <span>{{ properties.file_name }}</span>
      </b-col>
      <b-col
        cols="4"
        class="mt-2"
      >
        <h6>{{ $t('courses.size') }}:</h6>
        <span>{{ properties.file_size }}</span>
      </b-col>
      <b-col
        cols="4"
        class="mt-2"
      >
        <h6>{{ $t('courses.relation_count') }}:</h6>
        <span>{{ properties.relation_count }}</span>
      </b-col>
      <b-col
        cols="4"
        class="mt-2"
      >
        <h6>{{ $t('general.created_at') }}:</h6>
        <span>{{ new Date(properties.created_date).toLocaleString() }}</span>
      </b-col>
      <b-col cols="12">
        <b-table
          hover
          class="mt-2"
          responsive
          show-empty
          sticky-header="200px"
          :empty-text="$t('general.no_data_to_view')"
          :fields="fields"
          :items="GET_RELATION_SYSTEM"
          style-class="slots_text_align vgt-table bordered"
          @row-clicked="openHistory"
        >

          <template #head()="data">
            {{ $t(data.label) }}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal,
  BCol,
  BRow,
  BTable,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BModal,
    BCol,
    BRow,
    BTable,
  },
  props: {
    properties: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          label: 'users.course_title',
          key: 'course_title',
        },
        {
          label: 'courses.chapter_title',
          key: 'chapter_title',
        },
        {
          label: 'users.lesson_title',
          key: 'title',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('content', ['GET_RELATION_SYSTEM']),
  },
  methods: {
    ...mapActions('content', ['FETCH_RELATION_SYSTEM']),
    openHistory({ is_test, course_id, lesson }) {
      if (is_test) {
        this.$router.push(`/lessons/${lesson}/test-attachment`)
      } else {
        this.$router.push(`/course/course-administrations/theme-list/${course_id}/${lesson}`)
      }
    },
    onShow() {
      this.FETCH_RELATION_SYSTEM(this.properties.id)
    },
  },
}
</script>

<style>

</style>
